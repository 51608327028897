import React from 'react'
import { Link } from "gatsby";
import BackgroundImage from 'gatsby-background-image'

import "styles/GridCard.scss";

function addGradient(imageProp, toTop = false) {
  let gradient;

  if (toTop) {
    gradient = [
      imageProp,
      `linear-gradient(to top, rgba(0, 83, 112, 0) 0%, #008cbc 150%)`
    ].reverse();
  } else {
    gradient = [
      imageProp,
      `linear-gradient(to bottom, rgba(0, 83, 112, 0) 22%, #008cbc 104%)`
    ].reverse();
  }

  return gradient;
}

const GridCard = ({ image, path, cta, title}) => {
  return (
    <div className="col-md-6 col-lg-4 mb-5 grid-card">
      <BackgroundImage
        className="grid-card__inner d-flex justify-content-end align-items-end pb-4 pr-3"
        Tag="div"
        fixed={addGradient(image.localFile.childImageSharp.fixed)}
      >
        <Link to={path} className="button-transparent button-transparent--white">
          {cta}
        </Link>
      </BackgroundImage>

      <h3 className="grid-card__title with-dot-nested pt-3"><div dangerouslySetInnerHTML={{ __html: title }} /></h3>
    </div>
  )
}

export default GridCard;
