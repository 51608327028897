import React from 'react'
import { graphql } from 'gatsby';
import Image from 'gatsby-image'

import Layout from 'components/Layout'
import SEO from 'components/SEO';
import Content from 'components/Content';
import Cases from 'components/Cases';
import Hero from 'components/Hero';

import "styles/CaseTemplate.scss";

const Case = ({ data, location }) => {
  const { post, otherCases } = data
  const { acf: { thumbnail, hero, quote, klantcase } } = post;
  const url = location.href ? location.href : '';

  return (
    <Layout>
      <SEO yoast={{ meta: post.yoast_meta }} image={thumbnail.localFile.publicURL} lang="nl" pathname={url} />
      <div className="klantcase-page">
        <Hero
          className="klantcase-hero"
          fluidImage={hero.image.localFile.childImageSharp.fluid}
          title={post.title}
          description={hero.description}
          useH1
        />

        <section className="quote">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-md-10">
                <Content className="quote-content" content={quote.content} />
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-12 quote-person">
                <p>{quote.quoted_person.name}</p>
                <p>{quote.quoted_person.function}</p>
              </div>
            </div>
          </div>
        </section>

        <section className="case">
          <div className="container">
            <div className="row introduction">
              <Content className="introduction-content col-lg-6" content={klantcase.introduction} />
              <div className="col-lg-6 pl-0 pr-0">
                <Image
                  fixed={klantcase.image.localFile.childImageSharp.fixed}
                  className="introduction-image"
                />
              </div>
            </div>
            <div className="row case-content">
              <div className="col-md-12 col-lg-10">
                <Content className="case-content" content={klantcase.content} />
                {klantcase.after_content_image && (
                  <Image
                    fluid={klantcase.after_content_image.localFile.childImageSharp.fluid}
                  />
                )}
              </div>
            </div>
          </div>
        </section>

        <Cases otherCases={otherCases} />
      </div>
    </Layout>
  )
}

export default Case

export const query = graphql`
  query CaseByID($wordpress_id: Int!) {
    otherCases: allWordpressWpKlantcases(limit: 3, filter: { 
      status: { eq: "publish" }
      wordpress_id: { ne: $wordpress_id }
    }) {
      edges {
        node {
          title
          path
          acf {
            thumbnail {
              localFile {
                childImageSharp {
                  fixed(quality: 100, width: 340, height: 318) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }

    post: wordpressWpKlantcases(
      wordpress_id: { eq: $wordpress_id }
    ) {
      id
      wordpress_id
      title
      slug
      content
      yoast_meta {
        name
        content
        property
      }
      acf {
        thumbnail {
          localFile {
            publicURL
          }  
        }
          
        hero {
          description
          image {
            localFile {
              childImageSharp {
                fluid(quality: 99, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }

        quote {
          content
          quoted_person {
            name
            function
          }
        }

        klantcase: case {
          content
          image {
            localFile {
              childImageSharp {
                fixed(quality: 100, width: 720, height: 487) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
          introduction
          after_content_image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
