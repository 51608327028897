import React from 'react'

import { useStaticQuery, Link, graphql } from 'gatsby'

import GridCard from './GridCard';

const Cases = ({ otherCases, title, withButton = true }) => {
  const { klantcasesNL } = useStaticQuery(graphql`
    {
      klantcasesNL: wordpressWpComponenten(slug: {eq: "andere-klantcases"}) {
        ...klantcaseContent
      }
    }

    fragment klantcaseContent on wordpress__wp_componenten {
      acf {
        title
        cta
        cta_overview {
          text
          relations {
            path
          }
        }
      }
    }
  `)

  const { acf: caseContent } = klantcasesNL;


  if (otherCases.edges && otherCases.edges.length === 0) {
    return false;
  }

  return (
    <section className="other-cases">
      <div className="container">
        <h2 className="with-dot mb-4">{title || caseContent.title}</h2>
        <div className="row">
          {otherCases.edges.map(({ node }, key) => (
            <GridCard
              key={key}
              path={node.path}
              cta={caseContent.cta}
              image={node.acf.thumbnail}
              title={node.title}
            />
          ))}
          {withButton && (
            <div className="col-md-12 text-center">
              <Link className="button" to={caseContent.cta_overview.relations.path}>
                {caseContent.cta_overview.text}
              </Link>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default Cases;
